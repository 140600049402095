import {
  IconKey,
  IconWifi,
  IconParkingManagement,
  IconMobilePhone,
  IconDoorOpen,
  IconSmartHome,
  IconSmartPackageRoom,
  IconClipboardTask,
  IconPhone,
  IconSearch,
  IconCheck,
} from "@sr/gatsby-theme-ui"
import React, { ReactNode } from "react"

export interface NavigationOtherNavInfo {
  demo: {
    nav: string
    link: string
    internal: boolean
  }
  navInfo: {
    nav: string
    link: string
    internal: boolean
  }[]
}

export interface NavigationSubSectionContentNavigationItems {
  nav: string
  link: string
  icon?: () => ReactNode
}

export interface NavigationSubSectionContent {
  header?: string
  simple?: boolean
  nav?: string
  link?: string
  navSecondary?: string
  linkSecondary?: string
  iconAbove?: boolean
  navigationItems: NavigationSubSectionContentNavigationItems[]
}
export interface NavigationSubSection {
  nav: string
  content: NavigationSubSectionContent[]
}

const Solutions: NavigationSubSection = {
  nav: "Solutions",
  content: [
    {
      header: "Smart Communities",
      nav: "View All Solutions",
      link: "/products/",
      iconAbove: true,
      navigationItems: [
        {
          nav: "Access Control",
          link: "/products/access-control/",
          icon: () => <IconKey color={"var(--color-primary)"} />,
        },
        {
          nav: "Community Wifi",
          link: "/products/community-wifi",
          icon: () => <IconWifi color={"var(--color-primary)"} />,
        },
        {
          nav: "Parking Management",
          link: "/products/smart-parking-management/",
          icon: () => <IconParkingManagement color={"var(--color-primary)"} />,
        },
        {
          nav: "Resident App and Experience",
          link: "/products/resident-app-and-experience/",
          icon: () => <IconMobilePhone color={"var(--color-primary)"} />,
        },
        {
          nav: "Self-Guided Tours",
          link: "/products/self-guided-tours/",
          icon: () => <IconDoorOpen color={"var(--color-primary)"} />,
        },
        {
          nav: "Smart Apartments",
          link: "/products/smart-apartments-homes/",
          icon: () => <IconSmartHome color={"var(--color-primary)"} />,
        },
        {
          nav: "Package Management",
          link: "/products/smart-package-room/",
          icon: () => <IconSmartPackageRoom color={"var(--color-primary)"} />,
        },
      ],
    },
    {
      header: "Smart Operations",
      nav: "View All Integrations",
      link: "/integrations-partnerships/",
      iconAbove: true,
      navigationItems: [
        {
          nav: "Audit Management",
          link: "/products/audit-management/",
          icon: () => <IconClipboardTask color={"var(--color-primary)"} />,
        },
        {
          nav: "Answer Automation",
          link: "/products/answer-automation",
          icon: () => <IconPhone color={"var(--color-primary)"} />,
        },
        {
          nav: "Inspection Management",
          link: "/products/inspection-management/",
          icon: () => <IconSearch color={"var(--color-primary)"} />,
        },
        {
          nav: "Work Management",
          link: "/products/work-management/",
          icon: () => <IconCheck color={"var(--color-primary)"} />,
        },
      ],
    },
  ],
}

const Hardware: NavigationSubSection = {
  nav: "Hardware",
  content: [
    {
      header: "Alloy Hardware",
      nav: "All Alloy Hardware",
      link: "/hardware/alloy/",
      iconAbove: false,
      navigationItems: [
        {
          nav: "Hubs",
          link: "/hardware/hubs/",
        },

        {
          nav: "Sensors",
          link: "/hardware/sensors/",
        },
        {
          nav: "Readers",
          link: "/hardware/readers-panels-boards/",
        },
        {
          nav: "Intercoms",
          link: "/hardware/intercoms/",
        },
      ],
    },
    {
      header: "Additional Devices",
      nav: "All Hardware",
      link: "/hardware/",
      navSecondary: "All Integrations",
      linkSecondary: "/integrations-partnerships/",
      navigationItems: [
        {
          nav: "Locks & Lockboxes",
          link: "/hardware/locks-lock-boxes/",
        },
        {
          nav: "Thermostats",
          link: "/hardware/thermostats/",
        },
        {
          nav: "Lighting",
          link: "/hardware/smart-lighting/",
        },
      ],
    },
  ],
}

const WhySmartRent: NavigationSubSection = {
  nav: "Why SmartRent",
  content: [
    {
      header: "By Team",
      navigationItems: [
        {
          nav: "Leasing",
          link: "/products/leasing/",
        },
        {
          nav: "Maintenance",
          link: "/products/maintenance/",
        },
        {
          nav: "Management",
          link: "/products/management/",
        },
        {
          nav: "Operations",
          link: "/products/operations/",
        },
      ],
    },
    {
      header: "By Need",
      navigationItems: [
        {
          nav: "Asset Protection",
          link: "/products/asset-protection/",
        },
        {
          nav: "Centralization",
          link: "/products/centralization",
        },
        {
          nav: "Connected Communities",
          link: "/products/connected-communities",
        },
        {
          nav: "Resident Satisfaction",
          link: "/products/resident-satisfaction/",
        },
      ],
    },
    {
      header: "By Industry",
      navigationItems: [
        {
          nav: "Partner Program",
          link: "/customers/channel-partners-installers/",
        },
        {
          nav: "Homebuilders",
          link: "/customers/homebuilders/",
        },
        {
          nav: "Multifamily",
          link: "/customers/multifamily/",
        },
        {
          nav: "Single Family Rentals",
          link: "/customers/single-family-rentals/",
        },
        {
          nav: "Student Housing",
          link: "/customers/student-housing/",
        },
      ],
    },
  ],
}

const Resources: NavigationSubSection = {
  nav: "Resources",
  content: [
    {
      simple: true,
      navigationItems: [
        {
          nav: "About Us",
          link: "/about-us/",
        },
        {
          nav: "Leadership",
          link: "/about-smartrent/leadership-team/",
        },
        {
          nav: "Blog",
          link: "/news/",
        },
        {
          nav: "Case Studies",
          link: "/case-studies/",
        },
        {
          nav: "White Papers",
          link: "/whitepapers/",
        },
        {
          nav: "In The Press",
          link: "/news/press/",
        },
        {
          nav: "Resident Data & Security",
          link: "/data-security-privacy/",
        },
      ],
    },
  ],
}

const Contact: NavigationSubSection = {
  nav: "Contact",
  content: [
    {
      simple: true,
      navigationItems: [
        {
          nav: "Resident Support",
          link: "/support/",
        },
        {
          nav: "Let's Talk",
          link: "/contact-smartrent/demo/",
        },
        {
          nav: "Integrate with us",
          link: "/contact-smartrent/integration-request/",
        },
        {
          nav: "Be a Partner",
          link: "/customers/channel-partners-installers/",
        },
      ],
    },
  ],
}

const OtherNavInfo: NavigationOtherNavInfo = {
  demo: {
    nav: "Let's Talk",
    link: "/contact-smartrent/demo/",
    internal: true,
  },
  navInfo: [
    {
      nav: "Resident Support",
      link: "/support/",
      internal: true,
    },
    {
      nav: "Login",
      link: "https://control.smartrent.com/login",
      internal: false,
    },
    {
      nav: "Investors",
      link: "https://investors.smartrent.com/overview/default.aspx",
      internal: false,
    },
  ],
}

export { Solutions, Hardware, WhySmartRent, Resources, Contact, OtherNavInfo }

//take each navigationItems array from each main nav item object
//create an array for the main nav item and add each navigationItems item to it
// const contact = ["/contact-smartrent/demo", "/contact-smartrent/quote"]
